<script>
import {required, email, minLength} from "vuelidate/lib/validators";
import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
// import router from "@/router";

export default {
  name: "Register",
  data() {
    return {
      user: { email: "", password: "" },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      loading: false,
      waitActivationEmail: false
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  validations: {
    user: {
      email: { required, email },
      password: { required, minLength: minLength(6) }
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
    this.$store.dispatch('notification/error', null, { root: true });
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
          this.loading = true
          const {email, password } = this.user;
          if (email && password) {
            //@TODO: добавить обработку ошибки занятости email

            this.$store.dispatch('notification/error', null, { root: true });
            this.registeruser(this.user).then(
                () => {
                  this.loading = false
                  this.waitActivationEmail = true
                  // this.$store.dispatch('init/init').then((data) => {
                  //   console.log(data)
                  // })
                  // router.push('/');
                },
                error => {
                  this.loading = false
                  if (error['signupform-email'] !== undefined) {
                    this.$store.dispatch('notification/error', this.$t('Authentication.Register.Errors.EmailExists'), { root: true });
                    return
                  }
                  this.$store.dispatch('notification/error', this.$t('General.Errors.UnexpectedError'), { root: true });
                }
            )
          }

      }
    }
  }
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="https://eefim.com">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-dark.png" height="20" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">{{$t('Authentication.Register.Title')}}</h4>
                        <p class="text-muted">{{$t('Authentication.Register.Description')}}</p>
                      </div>

                      <div class="p-2 mt-2">
                        <b-alert
                          v-model="registerSuccess"
                          class="mt-3"
                          variant="success"
                          dismissible
                        >{{$t('Authentication.Register.SuccessMsg')}}</b-alert>

                        <b-alert
                          v-model="isRegisterError"
                          class="mt-3"
                          variant="danger"
                          dismissible
                        >{{regError}}</b-alert>

                        <b-alert
                          variant="danger"
                          class="mt-3"
                          v-if="notification.message"
                          show
                          dismissible
                        >{{notification.message}}</b-alert>

                        <form v-if="!waitActivationEmail" class="form-horizontal" @submit.prevent="tryToRegisterIn">

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">{{$t('Authentication.Register.Form.Email')}}</label>
                            <input
                              v-model="user.email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              :placeholder = "$t('Authentication.Register.Form.EmailPlaceholder')"
                              :class="{ 'is-invalid': submitted && $v.user.email.$error }"
                            />
                            <div v-if="submitted && $v.user.email.$error" class="invalid-feedback">
                              <span v-if="!$v.user.email.required">
                                {{$t('Authentication.Register.Errors.EmailRequired')}}
                              </span>
                              <span v-if="!$v.user.email.email">
                                {{$t('Authentication.Register.Errors.ErrorInEmail')}}
                              </span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">{{$t('Authentication.Register.Form.Password')}}</label>
                            <input
                              v-model="user.password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              :placeholder= "$t('Authentication.Register.Form.PasswordPlaceholder')"
                              :class="{ 'is-invalid': submitted && $v.user.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.user.password.required"
                              class="invalid-feedback"
                            >{{$t('Authentication.Register.Errors.PasswordRequired')}}</div>
                            <div
                                v-if="submitted && !$v.user.password.minLength"
                                class="invalid-feedback"
                            >{{$t('Authentication.Register.Errors.PasswordLength')}}</div>
                          </div>

                          <div class="text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              {{$t('Authentication.Register.Form.Register')}}
                              <b-spinner v-if="loading" small class="align-middle mr-1" variant="light" role="status">
                              </b-spinner>
                            </button>
                          </div>

                          <div class="mt-4 text-center">
                            <p class="mb-0">
                              {{$t('Authentication.Register.ApproveEmail')}}
                            </p>
                          </div>
                        </form>

                        <div v-if="waitActivationEmail" class="text-center mt-3">
                          {{ $t('Authentication.Register.TextWaitActivation', { email: user.email }) }}
                        </div>

                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          {{$t('Authentication.Register.IssetAccount')}}
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                          >{{$t('Authentication.Register.Authorization')}}</router-link>
                        </p>
<!--                        <p>-->
<!--                          © 2020 Nazox. Crafted with-->
<!--                          <i class="mdi mdi-heart text-danger"></i> by Themesdesign-->
<!--                        </p>-->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
